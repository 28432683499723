import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Button, IconButton } from '@material-ui/core';
import i18n from '../../../i18n/i18n';
import { toast } from 'react-toastify';
import ReactSelect from '../../../utils/ReactSelect';
import { getParcel, deleteParcel, setInitialState, setRowInReducer, getOwnershipDropDown } from '../../../actions/catastral_data/ParcelActions';
import AddParcel from './AddParcel';
// import { getFarmersDropdown } from '../../../actions/financial/IncentiveActions';
import { getLocalGov } from '../../../actions/DropDownActions';
import checkboxHOC from "react-table/lib/hoc/selectTable";
import immutable from 'seamless-immutable';
import { Delete } from '../../../utils/Delete';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import PreviewParcel from './PreviewParcel';
import axios from '../../../utils/AxiosWrapper';
import { wentWrong } from '../../../utils/ToastConfig';
import SearchBar from 'material-ui-search-bar';
import AddIcon from '@material-ui/icons/Add';
import { Tooltip } from 'chart.js';
import * as Icons from '@material-ui/icons'
import { Tooltip as Tooltip2 } from '@material-ui/core';
import ReactPaginate from 'react-paginate';
import { Export } from '../../../utils/Export';
import {  PulseLoader } from 'react-spinners';

var FileSaver = require('file-saver');
var XLSX = require('xlsx');

const CheckboxTable = checkboxHOC(ReactTable);
const styles = theme => ({
    searchWrap: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '20px 30px 10px 30px',
    },
    searchBar: {
        width: '40%',
        height: '40px !important',
        border: '1px solid #979797',
        maxWidth: '421px',
    },
    addNewBtn: {
        width: 'fit-content',
        height: 40,
        padding: '0 15px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: 0,
        backgroundColor: '#5EB3E4',
        '&:hover': {
            backgroundColor: '#4FA2CD',
        },
    },
    filtersSection: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '20px',
        paddingLeft: '30px',
        paddingTop: '18px',
    },
    dropDowns: {
        backgroundColor: '#F4F6F7',
        color: '#333333',
        fontSize: '14px',
        border: 'none',
        borderBottom: '1px solid #333333',
        marginRight: '20px',
        cursor: 'pointer',
        '&:focus': {
            outline: 'none',
        },
    },
    numberInput: {
        fontSize: '14px',
        marginRight: '20px',
        maxWidth: '130px',
        height: '19px',
        '&:focus': {
            outline: 'none',
        },
    },
    resetButton: {
        height: '25px',
        border: '2px solid #9b9658',
        borderRadius: '5px',
        cursor: 'pointer',
    },
    tableHeader: {
        height: '40px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#1F764E',
        borderTopRightRadius: '3px',
        borderTopLeftRadius: '3px',
    },
    tableHeaderTitle: {
        fontSize: '16px',
        color: 'white',
    },
    paginationContainer: {
        marginTop: 'auto',
        marginBottom: 0,
        listStyle: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '16px',
        userSelect: 'none',
        backgroundColor: 'white',
        padding: 0,
        color: '#7d7a7a',
        '& > li': {
            margin: '20px',
        },
        '& > .previous': {
            fontSize: '20px',
            padding: '5px',
            fontWeight: 'bold',
            width: '20px',
            height: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        '& > .next': {
            fontSize: '20px',
            padding: '5px',
            fontWeight: 'bold',
            width: '20px',
            height: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        '& > li > a': {
            cursor: 'pointer',
            color: '#7d7a7a',
        }
    },
    active: {
        border: '2px solid #7d7a7a',
        borderRadius: '50%',
        padding: '5px',
        width: '20px',
        height: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    exportButton: {
        width: '30px !important',
        height: '30px !important',
        minHeight: '30px !important',
        fontSize: '24px !important',
        marginLeft: 'auto !important',
        marginRight: '30px',
    },
    paginationButton: {
        borderRadius: '50%',
        width: '30px',
        height: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#5EB3E4',
        color: 'white',
        border: 'none',
        cursor: 'pointer'
    },
});
export class ViewParcel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            add: false,
            showFilters: false,
            selection: [],
            delete: false,
            selectAll: false,
            name: '',
            edit: false,
            preview: false,
            row: {},
            // -----------------------
            addNewParcel: false,
            editParcel: false,
            previewParcel: false,
            allParcels: [],
            filteredParcels: [],
            selectedGovernment: "",
            selectedLandArea: "",
            searchBarValue: "",
            currentPage: 0, // index based
            pageCount: 1,
            itemsPerPage: 8,
            isFirstRender: true,

            numberOfAllParcels: 0,
            numberOfFilteredParcels: 0,
            totalAreaAllParcels: 0,
            totalAreaFilteredParcels: 0,

            exportWindow: false,
            docTitle: null,

            //--------------------
            data: [],
            totalPages: 0,
            page: 0,
            pageSize: 10,
            sorted: null, 
            filtered: [],
            allGovernments: [],
            selectedGovernment: "",
            load: true
        };
    }

    componentDidMount() {
        if (this.props.localGoverments.length === 0) {
            this.props.getLocalGov();
        }
        if (this.props.ownershipDropDown.length === 0) {
            this.props.getOwnershipDropDown();
        }
        this.fetchAllGovernments();
        this.fetchTotal();
        // this.fetchAllParcels();
        
        this.setupPageCount();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedGovernment !== this.state.selectedGovernment ||
            prevState.selectedLandArea !== this.state.selectedLandArea ||
            prevState.searchBarValue !== this.state.searchBarValue) {
            if (!this.state.isFirstRender) this.setState({ currentPage: 0 });
            this.state.load = true;
            this.reactTable.fireFetchData();
            this.saveFiltersInSessionStorage();
        }

        if (prevState.editParcel !== this.state.editParcel) {
            console.log("bozidar edit");
            //this.fetchAllParcels();
        }

        if (prevState.filteredParcels !== this.state.filteredParcels) {
            this.setupPageCount();
        }
    }

    getData(state) {
        let filtered = [...this.state.filtered];

        if (this.state.searchBarValue) {
            filtered.push({ id: 'broj', value: this.state.searchBarValue });
        }
        if (this.state.selectedGovernment) {
            filtered.push({ id: 'naziv_kat_opstine', value: this.state.selectedGovernment });
        }
    
        if (this.state.selectedLandArea) {
            filtered.push({ id: 'povrsina', value: this.state.selectedLandArea });
        }

        this.setState({ data: [] , load: true});
        axios.get(`api/parcela/read`, {
            params: { page: state.page, pageSize: state.pageSize, sorted: state.sorted, filtered: filtered }
        })
            .then(res => {
                const formattedFilteredCount = parseFloat(res.data.filteredArea).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                this.setState({
                    data: res.data.data,
                    totalPages: res.data.total_pages,
                    totalAreaFilteredParcels: formattedFilteredCount,
                    numberOfFilteredParcels: res.data.filteredCount,
                    load: false
                },
            );
            })
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 1 (getData): ', error);
            })
    }

    fetchAllGovernments() {
        axios.get(`api/katastarska_opstina/drop_down_states_for_parcels`)
        .then(res => {
            const governments = res.data.data
                .map(item => ({ id: item.id, value: item.naziv }));
            this.setState({ allGovernments: governments,
                load: false
             });
        })
            .catch(() => wentWrong(i18n.t('Something went wrong')))
    }

    fetchTotal() {
        axios.get(`api/parcela/get_total_count_and_sum`)
        .then(res => {
            const formattedFilteredCount = parseFloat(res.data.data[0].totalArea).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
            this.setState({ totalAreaAllParcels: formattedFilteredCount,
                numberOfAllParcels: res.data.data[0].totalCount
             });
            console.log("total sum", res.data.data[0]);
        })
            .catch(() => wentWrong(i18n.t('Something went wrong')))
    }

    fetchAllParcels() {
        axios.get(`api/parcela/read_all_by_client`)
            .then(res => this.setState({ allParcels: res.data.data }, () => {
                // this.populateDropdowns();
                this.updateFilteredParcels();
                // this.setState({
                //     numberOfAllParcels: this.state.allParcels.length,

                // })
                // this.calculateTotalArea();
                console.log("this.state.allParcels : ", this.state.allParcels);
            }))
            .catch(() => wentWrong(i18n.t('Something went wrong')))
    }

    // calculateTotalArea() {
    //     let totalArea = 0
    //     this.state.allParcels.forEach(element => { totalArea += parseFloat(element.povrsina) });
    //     this.setState({ totalAreaAllParcels: totalArea })
    // }

    // calculateFilteredArea() {
    //     let totalArea = 0
    //     this.state.filteredParcels.forEach(element => { totalArea += parseFloat(element.povrsina) });
    //     this.setState({ totalAreaFilteredParcels: totalArea })
    // }

    // populateDropdowns() {
    //     let allGovernments = new Set(this.state.allParcels && this.state.allParcels.map((parcel) => (parcel.naziv_kat_opstine !== "" ? parcel.naziv_kat_opstine : null))
    //         .filter((value) => value !== null));
    //     let allLandAreas = new Set(this.state.allParcels && this.state.allParcels.map((parcel) => (parcel.povrsina !== "" ? parcel.povrsina : null))
    //         .filter((value) => value !== null));

    //     this.setState({
    //         allGovernments: [...allGovernments],
    //         allLandAreas: [...allLandAreas],
    //     }, () => this.updateFiltersFromSessionStorage());

    // }

    updateFilteredParcels() {
        this.setState({
            filteredParcels: this.state.allParcels
                .filter(parcel => {
                    if (this.state.selectedGovernment === "") return parcel;
                    if (!parcel.naziv_kat_opstine) return;
                    if (parcel.naziv_kat_opstine === this.state.selectedGovernment) return parcel;
                })
                .filter(parcel => {
                    if (this.state.selectedLandArea === "") return parcel;
                    if (!parcel.povrsina) return;
                    if (parcel.povrsina.includes(this.state.selectedLandArea)) return parcel;
                })
                .filter(parcel => {
                    if (this.state.searchBarValue === "") return parcel;
                    if (!parcel.broj) return;
                    if (parcel.broj.toLowerCase().includes(this.state.searchBarValue.toLowerCase())) return parcel;
                })
        }, () => {
            this.setupPageCount();
            this.setState({ numberOfFilteredParcels: this.state.filteredParcels.length })
            // this.calculateFilteredArea();
        });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            parcel: nextProps.parcel,
            parcelFetching: nextProps.parcelFetching,
            // farmerDropdown: immutable.asMutable(nextProps.farmerDropdown.map((item) => { return { label: item.kontakt_osoba, value: item.id } }), { deep: true }),
            localGoverments: immutable.asMutable(nextProps.localGoverments.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            ownershipDropDown: immutable.asMutable(nextProps.ownershipDropDown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
        })
        if (nextProps.deleted === true) {
            const wrapped = this.checkboxTable.getWrappedInstance();
            this.props.getParcel(wrapped.state);
            this.setState({
                selection: [],
                selectAll: false,
                name: ''
            })
            this.reactTable.fireFetchData();
            this.fetchTotal();
            this.fetchAllParcels();
            this.props.setInitialState('parcelObj');
            this.props.setInitialState('deleted');
        }
        if (nextProps.parcelPostingSuccess === true) {
            // this.handleClick();
            // this.fetchAllParcels();
            this.reactTable.fireFetchData();
            this.fetchTotal();
            // const wrapped = this.checkboxTable.getWrappedInstance();
            // this.props.getParcel(wrapped.state);
            this.props.setInitialState('parcelPostingSuccess');
        }
        if (nextProps.deleteParcelFailed === true) {
            toast.error(i18n.t('This item can not be deleted!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                draggablePercent: 80
            });
            this.props.setInitialState('deleteParcelFailed');
        }

    }

    onSearchClick() {
        this.setState({ showFilters: !this.state.showFilters });
    }

    handleClick = () => {
        if (this.state.edit === true && this.state.add === false) {
            this.setState({ edit: false })
            this.props.setInitialState('parcelObj');
        } else if (this.state.selection.length > 0 && this.state.add === false && this.state.edit === false) {
            this.props.setInitialState('parcelObj');
        }
        this.setState({ add: !this.state.add }, () => {
            if (this.state.add === true && this.state.edit === false) {
                this.setState({
                    selection: [],
                    name: '',
                    selectAll: false,
                })
            }
        });
    };

    toggleSelection = (key, shift, row) => {

        let selection = [...this.state.selection];
        const keyIndex = selection.indexOf(key);
        if (keyIndex >= 0) {
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];
            this.props.setInitialState('parcelObj');
        } else {
            selection.push(key);
        }
        this.setState({ selection, name: 'parcel' }, function () {
            if (selection.length === 1) {
                let row1 = this.props.parcel.filter((parc) => {
                    return parc.id === selection[0];
                })

                this.props.setRowInReducer(row1[0]);
            }
        });
    };

    toggleAll = () => {
        const selectAll = this.state.selectAll ? false : true;
        const selection = [];
        if (selectAll) {
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            currentRecords.forEach(item => {
                selection.push(item._original.id);
            });
        }
        this.setState({ selectAll, selection, name: 'parcel' });


    };

    isSelected = key => {
        return this.state.selection.includes(key);
    };

    onDeleteClick = async () => {
        let count = await this.countTablesByParcelId(this.state.selection)

        if (count > 0) {
            if (this.state.selection.length === 1) {
                if (count === 1) {
                    wentWrong(i18n.t('You cannot delete parcel that has active public green area on it'))
                } else {
                    wentWrong(i18n.t('You cannot delete parcel that has active public green areas on it!'))
                }
            } else if (this.state.selection.length > 1) {
                wentWrong(i18n.t('You cannot delete parcels that has active public green areas on it!'))

            }
        } else {
            this.setState({ delete: !this.state.delete });
        }
    }

    onExportClick = () => { this.setState({ export: !this.state.export }); };

    async countTablesByParcelId(id) {
        let count;
        await axios.post('api/parcela/count_active_fields_by_parcel_id', { params: { ids: [id] } })
            .then(res => {
                count = res.data.count
            })
            .catch(err => { console.log(err) })

        return count;
    }

    deleteItem = () => {
        this.props.deleteParcel(this.state.selection);
        this.setState({ delete: false })
    }


    handleDeleteButtonClick = async (id) => {

        let count = await this.countTablesByParcelId(id)

        if (count > 0) {

            if (count === 1) {
                wentWrong(i18n.t('You cannot edit parcel that has active public green area on it!'))
            } else {
                wentWrong(i18n.t('You cannot edit parcel that has active public green areas on it!'))
            }
        } else {
            this.setState({ deleteParcel: true })
            this.setState({ deletingParcelId: id });
        }
    }

    handleDeleteParcel = () => {
        this.props.deleteParcel([this.state.deletingParcelId]);
        this.setState({ deleteParcel: false })
        this.fetchAllParcels();
    }

    closeDeleteParcel = () => {
        this.setState({ deleteParcel: false });
        this.fetchAllParcels();
    }

    // async onEditClick() {
    //     let count = await this.countTablesByParcelId(this.state.selection)

    //     if (count > 0) {
    //         if (this.state.selection.length === 1) {
    //             if (count === 1) {
    //                 wentWrong(i18n.t('You cannot edit parcel that has active field on it!'))
    //             } else {
    //                 wentWrong(i18n.t('You cannot edit parcel that has active fields on it!'))
    //             }
    //         }
    //     } else {

    //         this.setState({ add: !this.state.add, edit: true });
    //     }

    // }

    async handleEditButtonClick(id) {
        let count = await this.countTablesByParcelId(id);
        if (count > 0) {

            if (count === 1) {
                wentWrong(i18n.t('You cannot edit parcel that has active field on it!'))
            } else {
                wentWrong(i18n.t('You cannot edit parcel that has active fields on it!'))
            }

        } else {

            this.setState({ editParcel: true });

        }

    }

    handleCloseEditParcel = () => {
        this.setState({ editParcel: false })
        this.fetchAllParcels();
    }

    handleButtonClick = (cell) => {
        let row = { id: cell._original.id, naziv: cell.broj };
        console.log('celija', cell);
        this.setState({ row })
        this.setState({ preview: !this.state.preview });

    };

    handlePreviewClick = (id, broj) => {
        let row = { id: id, naziv: broj };
        this.setState({ row })
        this.setState({ previewParcel: true });
    }

    handleClosePreviewParcel = () => {
        this.setState({ previewParcel: false });
    }

    onClosePreviewClick = () => {
        this.setState({ preview: !this.state.preview });
    }

    handleChangeGovernment = (e) => {
        this.setState({ selectedGovernment: e.currentTarget.value });
    }

    handleChangeLandArea = (e) => {
        this.setState({ selectedLandArea: e.currentTarget.value });
    }

    handleSearchBarChange = (searchValue) => {
        this.setState({
            searchBarValue: searchValue,
        });
    }

    resetMyFilters = () => {
        this.setState({
            selectedGovernment: "",
            selectedLandArea: "",
            searchBarValue: "",
            currentPage: 0,
        });
    }

    handleAddNewParcel = () => {
        this.setState({ addNewParcel: true });
    }

    handleCloseNewParcel = () => {
        this.setState({ addNewParcel: false });

        // this.fetchAllParcels();
    }

    handleNumberInput = (e) => {
        this.setState({ selectedLandArea: e.currentTarget.value });
    }

    setupPageCount = () => {
        const pageCount = Math.ceil(this.state.filteredParcels.length / this.state.itemsPerPage);
        this.setState({ pageCount: pageCount }, () => {
            if (this.state.currentPage >= this.state.pageCount && this.state.pageCount > 0) {
                this.setState({ currentPage: this.state.pageCount - 1 });
            }
        });
    }

    handlePageChange = (pageNum) => {
        this.setState({
            currentPage: pageNum.selected,
        })

        sessionStorage.setItem('ViewParcelPageNumber', pageNum.selected);
    }

    updateFiltersFromSessionStorage = () => {
        const storedVlues = sessionStorage.getItem('viewParcelFilterValues');

        if (storedVlues) {
            const parsedFilterValues = JSON.parse(storedVlues);

            this.setState({
                selectedGovernment: this.state.allGovernments.includes(parsedFilterValues.selectedGovernment) ? parsedFilterValues.selectedGovernment : "",
                selectedLandArea: parsedFilterValues.selectedLandArea,
                searchBarValue: parsedFilterValues.searchBarValue,
            }, () => {
                this.updatePageNumberFromSessionStorage();
                this.setupPageCount();
            });
        }


    }

    saveFiltersInSessionStorage = () => {
        const viewParcelFilterValues = JSON.stringify({
            selectedGovernment: this.state.selectedGovernment,
            selectedLandArea: this.state.selectedLandArea,
            searchBarValue: this.state.searchBarValue,

        })

        sessionStorage.setItem('viewParcelFilterValues', viewParcelFilterValues);

    }

    updatePageNumberFromSessionStorage = () => {
        if (this.state.isFirstRender) {
            const storedNumber = sessionStorage.getItem('ViewParcelPageNumber');

            if (storedNumber) {
                this.setState({ currentPage: Number(storedNumber) });
            } else {
                this.setState({ currentPage: 0 });
            }
        }

        this.setState({ isFirstRender: false });
    }
    handleCloseExportWindow = () => {
        this.setState({ exportWindow: false })
    }

    async exportDocument(title, data) {

        let newData = data.map(item => {
            return {
                Broj: item.broj,
                Katastarska_Opština: item.naziv_kat_opstine,
                Potes: item.potes ? item.potes : "",
                Klasa: item.klasa ? item.klasa : "",
                Površina: item.povrsina,
                Sektor: item.sektor_naziv,
                Vlasništvo: item.vlasnistvo,

            }
        });
        
        try {
            function s2ab(s) {
                const buf = new ArrayBuffer(s.length);
                const view = new Uint8Array(buf);
                for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
                return buf;
            }

            const ws = XLSX.utils.json_to_sheet(newData);

            ws['!cols'] = [
                { wpx: 100 },
                { wpx: 200 },
                { wpx: 200 },
                { wpx: 120 },
                { wpx: 120 },
                { wpx: 100 },
                { wpx: 150 },
                { wpx: 80 },
                { wpx: 180 },
                { wpx: 120 },
                { wpx: 120 },
                { wpx: 120 },
                { wpx: 120 },
                { wpx: 120 }
            ]
            ws['!rows'] = [{ hpx: 30 }];

            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

            const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
            const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
            FileSaver.saveAs(blob, `${title || 'file'}.xlsx`);

        } catch (err) {
            wentWrong(i18n.t('Something went wrong'))
            console.log("BLOB ERROR : ", err)
        }

    };

    exportFunction = () => {
        //console.log("exportFunction called");
        //console.log("Document Title:", this.state.docTitle);
        //console.log("Filtered Notes:", this.state.data);
        this.exportDocument(this.state.docTitle, this.state.data);
        this.setState({ exportWindow: false });
    }


    render() {
        const { theme } = this.props;
        const columns = [
            {
                Header: i18n.t('Number'),
                accessor: 'broj',
                headerStyle: { textAlign: 'center',
                textTransform: 'uppercase',
                marginLeft: 10,
                paddingTop: 10,
                paddingBottom: 10
                },
            },
            {
                width: 200,
                Header: i18n.t('Local government'),
                accessor: 'naziv_kat_opstine',
                headerStyle: { textAlign: 'center',
                textTransform: 'uppercase',
                paddingTop: 10,
                paddingBottom: 10
                },
                Filter: ({ filter, onChange }) =>
                    <ReactSelect
                        filter
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.localGoverments}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                Header: i18n.t('Potes'),
                accessor: 'potes',
                headerStyle: { textAlign: 'center',
                textTransform: 'uppercase',
                paddingTop: 10,
                paddingBottom: 10
                },
            },
            {
                Header: i18n.t('Class'),
                accessor: 'klasa',
                headerStyle: { textAlign: 'center',
                textTransform: 'uppercase',
                paddingTop: 10,
                paddingBottom: 10
                },
            },
            {
                widrth: 600,
                Header: i18n.t('Land area'),
                accessor: 'povrsina',
                headerStyle: { textAlign: 'center',
                textTransform: 'uppercase',
                paddingTop: 10,
                paddingBottom: 10
                },
            },
            {
                Header: i18n.t('Sector'),
                accessor: 'sektor',
                headerStyle: { textAlign: 'center',
                textTransform: 'uppercase',
                paddingTop: 10,
                paddingBottom: 10
                },
            },
            // ne treba za parklife
            // {
            //     Header: i18n.t('Ownership'),
            //     accessor: 'vlasnistvo',
            //     headerStyle: { textAlign: 'center',
            //     textTransform: 'uppercase',
            //     paddingTop: 10,
            //     paddingBottom: 10
            //     },
            // },
            {
                width: 80,
                Header: i18n.t('Details'),
                accessor: 'grupna',
                headerStyle: { textAlign: 'center',
                textTransform: 'uppercase',
                paddingTop: 10,
                paddingBottom: 10
                },
                Cell: cell => (
                    <div>
                        {cell.row.grupna === true &&
                            <Tooltip2 title={i18n.t("Details")}>
                            <IconButton aria-label="details"
                                onClick={() => {
                                    this.handleButtonClick(cell.row)
                                }}
                            >
                                <Icons.Visibility fontSize="default" />
                            </IconButton>
                        </Tooltip2>}
                    </div>
                )
            },
            {
                width: 80,
                Header: i18n.t('Edit'),
                accessor: 'izmena',
                headerStyle: { textAlign: 'center',
                textTransform: 'uppercase',
                paddingTop: 10,
                paddingBottom: 10
                },
                Cell: cell => (
                    <div>
                        <Tooltip2 title={i18n.t("Edit")}>
                                    <IconButton aria-label="copy"
                                        onClick={() => {
                                            this.setState({ parcelObj: cell.row._original })
                                            this.handleEditButtonClick(cell.row._original.id);
                                        }}
                                    >
                                        <Icons.Edit fontSize="default" />
                                    </IconButton>
                                </Tooltip2>
                    </div>
                )
            },
            {
                width: 80,
                Header: i18n.t('Delete'),
                accessor: 'detalji',
                headerStyle: { textAlign: 'center',
                textTransform: 'uppercase',
                paddingTop: 10,
                paddingBottom: 10
                },
                Cell: cell => (
                    <div>
                        <Tooltip2 title={i18n.t("Delete")}>
                                    <IconButton aria-label="Delete"
                                        onClick={() => this.handleDeleteButtonClick(cell.row._original.id)}
                                    >
                                        <Icons.DeleteOutline fontSize="default" />
                                    </IconButton>
                                </Tooltip2>
                    </div>
                )
            },

        ];
        const { toggleSelection, toggleAll, isSelected } = this;
        const { selectAll } = this.state;
        const checkboxProps = {
            selectAll,
            isSelected,
            toggleSelection,
            toggleAll,
            selectType: "checkbox",
            getTrProps: (state, rowInfo, instance) => {
                if (rowInfo) {
                    const selected = this.isSelected(rowInfo.original.id);
                    return {
                        style: {
                            backgroundColor: selected ? "#ACDAF1" : "inherit"
                        }
                    };
                }
                return {};
            },
            getTheadFilterProps: () => {
                return {
                    style: {
                        backgroundColor: theme.palette.primary.main
                    }
                };
            },
            getTheadTrProps: () => {
                return {
                    style: {
                        backgroundColor: theme.palette.secondary.main,
                        color: '#FFFFFF'
                    }
                };
            },
        };
        const filterOptions = {
            defaultFiltered:
                [
                    {
                        id: 'klijent_naziv',
                        value: this.props.klijent_naziv ? this.props.klijent_naziv : []
                    }
                ]
        };
        const { classes } = this.props;

        return (
            <div className="page">
                {/* ----------------------------------------search and add new parcel ------------------- */}
                <div className={classes.searchWrap}>
                    <SearchBar
                        className={classes.searchBar}
                        placeholder={i18n.t('Search by parcel number')}
                        cancelOnEscape={true}
                        value={this.state.searchBarValue}
                        onChange={(searchValue) => this.handleSearchBarChange(searchValue)}
                        // onRequestSearch={(searchValue) => this.handleSearchBarChange(searchValue)}
                        onCancelSearch={() => this.handleSearchBarChange('')}
                    />

                    <div style={{ display: 'flex' }}>

                        <div style={{ marginRight: '30px' }}>
                            <div style={{ backgroundColor: 'white', padding: '0 10px 0 10px', display: 'flex', minWidth: '250px', justifyContent: 'space-between', border: '1px solid #979797', boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)' }}>
                                <div
                                // style={{ fontSize: '12px' }}
                                >{i18n.t('Total number of parcels')}:</div>
                                <div>{this.state.numberOfAllParcels}</div>
                            </div>
                            <div style={{ backgroundColor: 'white', padding: '0 10px 0 10px', display: 'flex', minWidth: '250px', justifyContent: 'space-between', border: '1px solid #979797', boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)' }}>
                                <div
                                // style={{ fontSize: '12px' }}
                                >{i18n.t('Total number of filtered parcels')}:</div>
                                <div>{this.state.numberOfFilteredParcels}</div>
                            </div>
                        </div>

                        <div style={{ marginRight: '30px' }}>
                            <div style={{ backgroundColor: 'white', padding: '0 10px 0 10px', display: 'flex', minWidth: '350px', justifyContent: 'space-between', border: '1px solid #979797', boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)' }}>
                                <div
                                // style={{ fontSize: '12px' }}
                                >{i18n.t('Total area of all parcels')}:</div>
                                <div>{this.state.totalAreaAllParcels} ha</div>
                            </div>
                            <div style={{ backgroundColor: 'white', padding: '0 10px 0 10px', display: 'flex', minWidth: '350px', justifyContent: 'space-between', border: '1px solid #979797', boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)' }}>
                                <div
                                // style={{ fontSize: '12px' }}
                                >{i18n.t('Total area of filtered parcels')}:</div>
                                <div>{this.state.totalAreaFilteredParcels} ha</div>
                            </div>
                        </div>

                    </div>

                    <Button
                        variant="fab"
                        color="primary"
                        aria-label="Add"
                        className={classes.addNewBtn}
                        onClick={() => this.handleAddNewParcel()}
                    >
                        <AddIcon />
                        {i18n.t('Add parcel')}
                    </Button>

                </div>
                {/* -------------------------------------------------------------------------- */}
                {/* ------------------------------- FILTERS ------------------------------------ */}
                <div className={classes.filtersSection}>
                    <select className={classes.dropDowns} value={this.state.selectedGovernment} onChange={this.handleChangeGovernment} name="government" id="government">
                        <option value="">{i18n.t('Local government')}</option>
                        {this.state.allGovernments && this.state.allGovernments.map((status, i) => (
                            <option key={i} value={status.value}>{status.value}</option>
                        ))}
                    </select>

                    <label style={{ fontSize: '14px', marginRight: '20px', cursor: 'pointer' }} htmlFor="numberInput">{i18n.t('Land area')}:</label>
                    <input value={this.state.selectedLandArea} onChange={(e) => this.handleNumberInput(e)} type="number" name='numberInput' id='numberInput' className={classes.numberInput} />

                    {/* <button className={classes.resetButton} onClick={this.resetMyFilters}>Resetuj filtere</button> {i18n.t("Total area of filtered parcels: ")}{this.state.totalArea} */}
                    <Button
                        onClick={() => this.setState({ exportWindow: !this.state.exportWindow })}
                        variant="fab"
                        color="secondary"
                        aria-label="add"
                        className={classes.exportButton}
                    // style={{ marginLeft: 'auto !important' }}

                    >
                        <span className="icon-download headerIcon" />
                    </Button>
                </div>
                <div style={{ border: '1px solid #cecece', backgroundColor: '#fff', margin: '0 30px 30px 30px', borderRadius: '3px', boxSizing: 'border-box', overflow: 'hidden', boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)', display: 'flex', flexDirection: 'column' }}>
                    <ReactTable ref={r => (this.reactTable = r)}
                        manual
                        paging={true}
                        data={this.state.data}
                        columns={columns}
                        keyField={'id'}
                        pageRangeDisplayed={false}
                        defaultPageSize={this.state.pageSize}
                        filterable={this.state.showFizlters}
                        pages={this.state.totalPages}
                        showPaginationBottom={true}
                        onFetchData={(state) => this.getData(state)}
                        // loading={this.state.load}
                        firtelable={true}
                        previousText={'<'}
                        nextText={'>'}
                        // noDataText={!this.state.load && i18n.t('No rows found')}
                        pageText={''}
                        ofText={i18n.t('of')}
                        showPageSizeOptions={false}
                        showPagination={true}
                        rowsText={''}
                        {...checkboxProps}
                        {...filterOptions}
                        PreviousComponent={(props) => (
                            <button {...props} 
                            className={
                                classes.paginationButton}
                            >{props.children}</button>
                        )}
                        NextComponent={(props) => (
                            <button {...props} className={
                                classes.paginationButton}
                            >{props.children}</button>
                        )}
                        getTrProps={() => {
                                return {
                                    style: {
                                        textAlign: 'center',
                                        paddingLeft: '10px',
                                        height: '50px',
                                        alignItems: 'center',
                                        overflowY: 'hidden',
                                        overflowX: 'hidden',
                                    }
                                };
                            
                        }}
                        LoadingComponent={() => (
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <PulseLoader loading={this.state.load}/>
                            </div>                         
                        )}
                        onPageChange={() => {
                            this.state.load= true ;
                          }}
                          NoDataComponent={() => (
                            <div style={{ textAlign: 'center', padding: '20px', display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: -300 }}>
                                {!this.state.load && <span>{i18n.t('No rows found')}</span>}
                            </div>
                        )}
                    />
                </div>
                {/* ------------------------------------------------------------------------- */}
                {/* -----------------------------------TABLE---------------------------------------- */}
                {/* <div style={{ border: '1px solid #cecece', backgroundColor: '#fff', margin: '0 30px 30px 30px', borderRadius: '3px', boxSizing: 'border-box', overflow: 'hidden', boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)', minHeight: 'calc(100vh - 500px)', display: 'flex', flexDirection: 'column' }}>
                    <div className={classes.tableHeader}>
                        <div className={classes.tableHeaderTitle} >Sve parcele</div>
                    </div> */}

                    {/* ----------------------------------first row------------------------------ */}
                    {/* <div style={{ display: 'flex', alignItems: "center", justifyContent: 'space-between', margin: '0 16px', padding: '15px 0 8px 0', borderBottom: '1px solid #9b9658', fontSize: '14px', color: '#7d7a7a' }}> */}
                        {/* <div style={{ width: '60px', marginRight: "20px" }}>{i18n.t("Number")}</div> */}
                        {/* <div style={{ width: '250px', marginRight: "20px" }}>{i18n.t("Number")}</div>
                        <div style={{ width: '250px', marginRight: "20px" }}>{i18n.t("Local government")}</div>
                        <div style={{ width: '140px', marginRight: "20px" }}>{i18n.t("Potes")}</div>
                        <div style={{ width: '60px', marginRight: "20px" }}>{i18n.t("Class")}</div>
                        <div style={{ width: '170px', marginRight: "20px" }}>{i18n.t("Land area")}</div>
                        <div style={{ width: '100px', marginRight: "20px" }}>{i18n.t("Sector")}</div>
                        <div style={{ width: '80px', marginRight: "20px" }}>{i18n.t("Ownership")}</div>
                        <div style={{ width: '177px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div style={{ width: '60px', marginRight: "20px", paddingLeft: '10px' }}>{i18n.t("Details")}</div>
                            <div style={{ width: '60px', marginRight: "20px" }}>{i18n.t("Edit")}</div>
                            <div style={{ width: '60px', marginRight: "20px" }}>{i18n.t("Delete")}</div>
                        </div>
                    </div> */}

                    {/* ----------------------------------------------------------- */}
                    {/* --------------------------Filtered Parcels------------------------ */}
                    {/* {this.state.filteredParcels && this.state.filteredParcels.slice(this.state.currentPage * this.state.itemsPerPage, (this.state.currentPage + 1) * this.state.itemsPerPage).map((item, i) => (
                        <div key={i} style={{ display: 'flex', alignItems: "center", justifyContent: 'space-between', margin: '0 16px', borderBottom: '1px solid #d2d2d2' }}>
                            <div style={{ width: '250px', marginRight: "20px", fontSize: '14px' }}>{item.broj}</div>
                            <div style={{ width: '250px', marginRight: "20px", fontSize: '14px', color: '#333333' }}>{item.naziv_kat_opstine}</div>
                            <div style={{ width: '140px', marginRight: "20px", fontSize: '14px', color: '#333333' }}>{item.potes}</div>
                            <div style={{ width: '60px', marginRight: "20px", fontSize: '14px', color: '#333333' }}>{item.klasa}</div>
                            <div style={{ width: '170px', marginRight: "20px", fontSize: '14px', color: '#333333' }}>{item.povrsina}</div>
                            <div style={{ width: '100px', marginRight: "20px", fontSize: '14px', color: '#333333' }}>{item.sektor_naziv}</div>
                            <div style={{ width: '80px', marginRight: "20px", fontSize: '14px', color: '#333333' }}>{item.vlasnistvo}</div>

                            <div style={{ minWidth: '177px', display: 'flex', justifyContent: 'flex-end' }}>
                                {item.grupna && <Tooltip2 title={i18n.t("Details")}>
                                    <IconButton aria-label="details"
                                        onClick={() => this.handlePreviewClick(item.id, item.broj)}
                                    >
                                        <Icons.Visibility fontSize="large" />
                                    </IconButton>
                                </Tooltip2>}
                                <Tooltip2 title={i18n.t("Edit")}>
                                    <IconButton aria-label="copy"
                                        onClick={() => {
                                            this.setState({ parcelObj: item })
                                            this.handleEditButtonClick(item.id, item)
                                        }}
                                    >
                                        <Icons.Edit fontSize="large" />
                                    </IconButton>
                                </Tooltip2>
                                <Tooltip2 title={i18n.t("Delete")}>
                                    <IconButton aria-label="Delete"
                                        onClick={() => this.handleDeleteButtonClick(item.id)}
                                    >
                                        <Icons.DeleteOutline fontSize="large" />
                                    </IconButton>
                                </Tooltip2>
                            </div>
                        </div>

                    ))} */}

                    {/* <ReactPaginate
                        pageCount={20}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={1}
                        onPageChange={this.handlePageChange}
                        containerClassName={classes.paginationContainer}
                        activeClassName={classes.active}
                        previousLabel={"<"}
                        nextLabel={">"}
                        forcePage={this.state.currentPage}
                    />
                </div> */}
                {/* ------------------------------------------------------------- */}

                {this.props.isParcelShown ?
                    <div style={{ display: 'none' }}>
                        <DialogTitle disableTypography={true} style={{ background: theme.palette.secondary.dark }} className="responsive-dialog-title">{i18n.t('Parcels')}
                            <IconButton onClick={this.props.handleCloseParcel}>
                                <span className="icon-cancel-circle closeIcon" />
                            </IconButton>
                        </DialogTitle>
                    </div>
                    : null}
                <div className="table-header" style={{ display: 'none' }}>
                    {!this.props.klijent_naziv ?
                        <Button
                            onClick={() => this.handleClick()}
                            variant="fab"
                            color="secondary"
                            aria-label="add"
                            className="action-button"
                        >
                            <span className="icon-Plus headerIcon" />
                        </Button> : null}
                    {!this.props.klijent_naziv ?
                        <Button
                            onClick={() => this.onSearchClick()}
                            variant="fab"
                            color="secondary"
                            aria-label="add"
                            className="action-button"
                        >
                            <span className="icon-search headerIcon" />
                        </Button> : null}
                    {this.state.selection.length > 0 ?
                        <Button
                            onClick={() => this.onDeleteClick()}
                            variant="fab"
                            color="secondary"
                            aria-label="add"
                            className="action-button"
                        >
                            <span className="icon-Kanta headerIcon" />
                        </Button>
                        : null
                    }
                    {this.state.selection.length === 1 ?
                        <Button
                            onClick={() => this.onEditClick()}
                            variant="fab"
                            color="secondary"
                            aria-label="edit"
                            className="action-button"
                        >
                            <span className="icon-Izmeni1 headerIcon" />
                        </Button>
                        : null
                    }
                </div>
                <CheckboxTable
                    style={{ display: 'none' }}
                    data={this.props.parcel}
                    ref={r => (this.checkboxTable = r)}
                    keyField={'id'}
                    // getTheadFilterThProps={() => { return { style: { position: "inherit", overflow: "inherit" } } }}
                    columns={columns}
                    pageSizeOptions={[10, 25, 50, 100]}
                    defaultPageSize={25}
                    filterable={this.state.showFilters}
                    pages={this.state.parcelPages}
                    loading={this.props.parcelFetching}
                    manual
                    showPaginationTop
                    showPaginationBottom={false}
                    onFetchData={(state, instance) => this.props.getParcel(state, instance)}
                    className="-striped -highlight"
                    previousText={i18n.t('Previous')}
                    nextText={i18n.t('Next')}
                    loadingText={i18n.t('Loading...')}
                    noDataText={i18n.t('Loading...')}
                    pageText={i18n.t('Page')}
                    ofText={i18n.t('of')}
                    rowsText={i18n.t('rows')}
                    {...checkboxProps}
                    {...filterOptions}
                    PreviousComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: theme.palette.primary.main,
                            color: '#FFFFFF'
                        }}>{props.children}</button>
                    )}
                    NextComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: theme.palette.primary.main,
                            color: '#FFFFFF'
                        }}>{props.children}</button>
                    )}
                />
                {this.state.add && <AddParcel history={this.props.history} add={this.state.add} edit={this.state.edit}
                    handleClose={this.handleClick} />}

                {this.state.delete && <Delete delete={this.state.delete} handleClose={this.onDeleteClick} deleteItem={this.deleteItem} />}

                {this.state.preview && <PreviewParcel open={this.state.preview} row={this.state.row} handleClose={this.onClosePreviewClick} naziv={this.deleteItem} />}

                {/* ---------------------------------------- */}


                {this.state.exportWindow && <Export
                    export={this.state.exportWindow}
                    onTextChange={({ title }) => { this.setState({ docTitle: title }); }}
                    // handleClose={() => this.setState({ exportWindow: !this.state.exportWindow })}
                    handleClose={this.handleCloseExportWindow}
                    exportDoc={this.exportFunction} />}

                {this.state.addNewParcel && <AddParcel history={this.props.history} add={true} edit={false}
                    handleClose={this.handleCloseNewParcel} />}

                {this.state.previewParcel && <PreviewParcel open={this.state.previewParcel} row={this.state.row} handleClose={this.handleClosePreviewParcel} naziv={this.deleteItem} />}


                {this.state.editParcel && <AddParcel history={this.props.history} add={true} edit={true}
                    handleClose={this.handleCloseEditParcel} editParcelObject={this.state.parcelObj} />}

                {this.state.deleteParcel && <Delete delete={this.state.deleteParcel} handleClose={this.closeDeleteParcel} deleteItem={this.handleDeleteParcel} />}

            </div>

        )
    }


};

function mapStateToProps(state) {
    return {
        parcel: state.parcelReducer.parcel,
        parcelFetching: state.parcelReducer.parcelFetching,
        parcelPages: state.parcelReducer.parcelPages,
        deleted: state.parcelReducer.deleted,
        deleteParcelFailed: state.parcelReducer.deleteParcelFailed,
        parcelPostingSuccess: state.parcelReducer.parcelPostingSuccess,
        parcelObj: state.parcelReducer.parcelObj,
        localGoverments: state.appReducer.localGoverments,
        ownershipDropDown: state.parcelReducer.ownershipDropDown,
        // farmerDropdown: state.incentiveReducer.farmerDropdown,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getParcel: (state, instance) => dispatch(getParcel(state, instance)),
        deleteParcel: (selection, name) => dispatch(deleteParcel(selection, name)),
        setInitialState: (component) => dispatch(setInitialState(component)),
        setRowInReducer: (rowInfo) => dispatch(setRowInReducer(rowInfo)),
        getLocalGov: () => dispatch(getLocalGov()),
        getOwnershipDropDown: () => dispatch(getOwnershipDropDown()),
        // getFarmersDropdown: () => dispatch(getFarmersDropdown()),
    }
}

export default withStyles(styles, { withTheme: true })(connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewParcel))