import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../../i18n/i18n';
import { Button, IconButton, Typography, Switch, InputAdornment } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Grid from "@material-ui/core/Grid";
import styles from './newactivity.module.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setInitialState, addParcel, getOwnershipDropDown, addGroupedParcel } from '../../../actions/catastral_data/ParcelActions';
import { getLocalGov, getKatKultura } from '../../../actions/DropDownActions';
import { getUser } from '../../../utils/CommonFunctions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import ManageParcels from './ManageParcels';
import axios from '../../../utils/AxiosWrapper';
import { toNumber } from 'lodash';

export class AddParcel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            parcelObj: this.props.editParcelObject ? this.props.editParcelObject : this.props.parcelObj,
            new: false,
            parcelsOpen: false,
            // this.state.new postavlja uslove kada da se otvori desna a kada leva strana switch-a
            new: false,
            featureGroupedParcelsOn: null,
            ogranakDropdown: [],
            hasSector: null
        }
    }

    componentDidMount() {
        // if (this.props.parcelObj.grupna) {
        //     if (this.props.parcelObj.grupna === true) { this.setState({ new: true }) }
        // } else if (this.props?.editParcelObject?.grupna) { if (this.props.editParcelObject.grupna === true) { this.setState({ new: true }) } };

        axios.get('api/sektor/drop_down')
            .then(res => this.setState({ ogranakDropdown: res.data.data }, () => console.log("this.state.ogranakDropdown : ", this.state.ogranakDropdown)))
            .catch(err => console.log('sektor dropdown ERROR', err));

        if (this.props.parcelObj && this.props.parcelObj.grupna) {
            if (this.props.parcelObj.grupna === true) {
                this.setState({ new: true });
            }
        } else if (this.props.editParcelObject && this.props.editParcelObject.grupna) {
            if (this.props.editParcelObject.grupna === true) {
                this.setState({ new: true });
            }
        }

        const { povrsina } = this.props;
        this.props.getKatKultura();
        this.props.getOwnershipDropDown();
        this.props.getLocalGov();
        if (povrsina && povrsina !== null) {
            let copyObj = Object.assign({}, this.state.parcelObj);
            copyObj.povrsina = povrsina;
            this.setState({
                parcelObj: copyObj
            })
        }

        if (typeof (this.props.parcelObj.naziv) === 'string') {
            let copyObj = Object.assign({}, this.state.parcelObj);
            copyObj.naziv = this.props.parcelObj.naziv.substring(2);
            this.setState({
                parcelObj: copyObj
            })
        }

        if (typeof (this.props.editParcelObject && this.props.editParcelObject.naziv && this.props.editParcelObject.naziv) === 'string') {
            let copyObj = Object.assign({}, this.state.parcelObj);
            copyObj.naziv = this.props.editParcelObject.naziv.substring(2);
            this.setState({
                parcelObj: copyObj
            })
        }

        if (this.props.parcelObj.grupna === true) {
            axios.post('api/parcela/get_parts', { params: { id: this.props.parcelObj.id } })
                .then(res => {
                    let result = res.data.data
                    let parcele = []
                    let copyObj = Object.assign({}, this.state.parcelObj);

                    result.forEach(element => {
                        parcele.push(element)
                    });
                    copyObj.parcele = parcele
                    this.setState({
                        parcelObj: copyObj
                    })
                })
                .catch(err => { console.log(err) })
        }

        if (this.props.editParcelObject && this.props.editParcelObject.grupna && this.props.editParcelObject.grupna === true) {
            axios.post('api/parcela/get_parts', { params: { id: this.props.editParcelObject.id } })
                .then(res => {
                    let result = res.data.data
                    let parcele = []
                    let copyObj = Object.assign({}, this.state.parcelObj);

                    result.forEach(element => {
                        parcele.push(element)
                    });
                    copyObj.parcele = parcele
                    this.setState({
                        parcelObj: copyObj
                    })
                })
                .catch(err => { console.log(err) })
        }

        let idKlijent = this.getUser().id_klijent
        axios.post('api/profile/check_feature_grouped_parcels', { params: { id: idKlijent } })
            .then(res => {
                let featureGroupedParcelsOn = res.data.data[0]['feature_parcela_grupna']
                this.setState({
                    featureGroupedParcelsOn: featureGroupedParcelsOn
                })
            })
            .catch(err => { console.log(err) })

            let hasSector;
            axios.get('api/sektor/drop_down')
            .then(res => {
                res.data.data.length > 0 ?  hasSector = true : hasSector=false
                this.setState({hasSector: hasSector})
            })
            .catch(err => { console.log(err) })
    }

    getUser() {
        let user = localStorage.getItem('user');
        let userObject = JSON.parse(user);
        !user && window.location.replace(window.location.origin);
        return userObject;
    }
    handleParcels = () => this.setState({ parcelsOpen: !this.state.parcelsOpen });
    isOdd = (num) => { return num % 2; };

    deleteParcels = (row) => {
        let obj = this.state.parcelObj;
        let parcele = obj.parcele;
        delete obj.parcele;
        parcele.splice(row, 1);
        obj.parcele = parcele;
        if (obj.parcele.length === 0) obj.parcele = undefined;
        this.setState({ parcelObj: obj });
    };

    handleParcelObj = (parcels) => {
        let obj = Object.assign({}, this.state.parcelObj);
        obj.parcele = parcels;
        this.setState({ parcelObj: obj }, () => this.handleParcels());
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.parcelPostingFailed === true) {
            toast.error(i18n.t('Something went wrong, please try again!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                draggablePercent: 80
            });
            this.props.setInitialState('parcelPostingFailed');
        }
    }

    addParcel() {
        const { onSave } = this.props;
        const { parcelObj } = this.state;

        if (this.props.edit === true) {
            delete parcelObj.sektor_naziv
        }

        if (onSave) {
            return onSave(parcelObj);
        }

        if (this.state.parcelObj.broj &&
            this.state.parcelObj.povrsina &&
            (this.state.parcelObj.id_sektor || !this.state.hasSector)) {
            this.props.addParcel(
                this.state.parcelObj)
            this.props.handleClose();
        } else {
            toast.error(i18n.t('Please enter all required fields!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                draggablePercent: 80
            });
        }
    }
    addParcelGrouped() {

        const { onSave } = this.props;
        const { parcelObj } = this.state;
        if (onSave) {
            return onSave(parcelObj);
        }
        if (this.state.parcelObj.naziv &&
            this.state.parcelObj.parcele &&
            this.state.parcelObj.parcele.length > 1) {
            let parcelsToGroup = [];
            let parcelArea = 0;

            this.state.parcelObj.parcele.forEach(element => {
                if (element['value'] == undefined) {
                    parcelsToGroup.push(element['id'])
                } else {
                    parcelsToGroup.push(element['value'])
                }
                parcelArea += toNumber(element['povrsina'])
            });
            let parcelObj = {}
            // parcelObj.id_parcele ? parcelObj.id_parcele = this.state.parcelObj.id : parcelObj.id_parcele = null
            parcelObj.id_parcele = this.state.parcelObj.id
            parcelObj.parcele = parcelsToGroup
            parcelObj.naziv = 'g_' + this.state.parcelObj.naziv
            parcelObj.broj = 'g_' + this.state.parcelObj.naziv
            parcelObj.parcelArea = parcelArea
            parcelObj.id_sektor = this.state.parcelObj.parcele[0].id_sektor
            parcelObj.sektor_naziv = this.state.parcelObj.parcele[0].sektor_naziv
            // axios.post('api/parcela/create_grouped', { params: { id_parcele: this.state.parcelObj.id , parcele: parcelsToGroup, naziv : this.state.parcelObj.naziv, parcelArea: parcelArea } })
            // .then(res => { this.props.handleClose(); this.props.history.push('/parcel') })
            // .catch(err => { console.log(err) })

            this.props.addGroupedParcel(parcelObj)
            this.props.handleClose();

        } else if (this.state.parcelObj.naziv === undefined || this.state.parcelObj.naziv === null || this.state.parcelObj.naziv === '') {
            toast.error(i18n.t('Please enter grouped parcel title!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                draggablePercent: 80
            });
        } else if (this.state.parcelObj.naziv && (this.state.parcelObj.parcele === undefined || this.state.parcelObj.parcele.length < 2)) {
            toast.error(i18n.t('You have to select at least two field!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                draggablePercent: 80
            });
        }
    }

    handleChangeValue = name => event => {
        let obj = Object.assign({}, this.state.parcelObj);
        obj[name] = event.target.value;
        this.setState({ parcelObj: obj });
    };


    render() {
        let localGoverments = [];
        if (this.props.localGoverments && !this.props.localGovermentsFetching) {
            localGoverments = this.props.localGoverments.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }
        let katKultureArr = [];
        if (this.props.kat_kulture && !this.props.kat_kultureFetching) {
            katKultureArr = this.props.kat_kulture.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }
        let ownershipDropDown = [];
        if (this.props.ownershipDropDown && !this.props.ownershipFetchingDropDown) {
            ownershipDropDown = this.props.ownershipDropDown.map((s, i) => {
                return <option key={s.id} value={s.id}>{s.naziv}</option>
            });
        }
        const { theme } = this.props;
        return (
            <div style={{ padding: 20 }}>

                {/* <Paper className="roundedBoxMaterial" zdepth={4}> */}
                <Dialog
                    open={this.props.add}
                    onClose={this.props.handleClose}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth="sm"
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle disableTypography={true} style={{ background: theme.palette.secondary.dark }} className="responsive-dialog-title">{this.state.new ? (this.props.edit ? i18n.t('Edit grouped parcel') : i18n.t('Create grouped parcel')) : this.props.edit ? i18n.t('Edit parcel') : i18n.t('Add parcel')}
                        {!this.props.edit && this.state.featureGroupedParcelsOn && < div style={{ display: 'flex' }}>
                            <Typography style={{ fontSize: '18px', color: 'white', alignSelf: 'center' }}>{i18n.t('New')}</Typography>
                            <Switch
                                checked={this.state.new}
                                onClick={(e) => {
                                    this.setState({ new: !this.state.new });
                                    // if (this.state.naziv === null) {
                                    // this.setState({ field: null });
                                    // }
                                    // else this.setState({ naziv: null });
                                }}
                                color="default"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                            <Typography style={{ fontSize: '18px', color: 'white', alignSelf: 'center' }}>{i18n.t('Grouped')}</Typography>
                        </div>}
                        <IconButton onClick={this.props.handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        {!this.state.new && <form id="add-layer">
                            <Grid container justify="space-between" alignContent="space-between" spacing={16}>
                                <Grid item>
                                    <TextField
                                        required
                                        label={i18n.t('Number')}
                                        value={this.state.parcelObj.broj}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        onChange={this.handleChangeValue('broj')}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        required
                                        label={i18n.t('Land area') + ' (ha)'}
                                        value={this.state.parcelObj.povrsina}
                                        onChange={this.handleChangeValue('povrsina')}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        type="number"
                                    />
                                </Grid>
                                {/* <Grid item >
                                    <TextField
                                        select
                                        label={i18n.t('Ownership')}
                                        value={this.state.parcelObj.id_vlasnistvo}
                                        onChange={this.handleChangeValue('id_vlasnistvo')}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        margin="normal"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    >
                                        <option value='' />
                                        {ownershipDropDown}
                                    </TextField>
                                </Grid> */}
                                <Grid item>
                                    <TextField
                                        select
                                        label={i18n.t('Local government')}
                                        value={this.state.parcelObj.id_katastarska_opstina}
                                        onChange={this.handleChangeValue('id_katastarska_opstina')}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        margin="normal"
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    >
                                        <option value='' />
                                        {localGoverments}
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        // required
                                        label={i18n.t('Potes')}
                                        value={this.state.parcelObj.potes}
                                        onChange={this.handleChangeValue('potes')}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    />
                                </Grid>
                                {/* <Grid item >
                                    <TextField
                                        label={i18n.t('Immovable property number')}
                                        value={this.state.parcelObj.br_lista}
                                        onChange={this.handleChangeValue('br_lista')}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        type="number"
                                    />
                                </Grid> */}
                                <Grid item>
                                    <TextField
                                        // required
                                        type="number"
                                        pattern="[0-9]*"
                                        label={i18n.t('Class')}
                                        value={this.state.parcelObj.klasa}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        onChange={this.handleChangeValue('klasa')}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        required= {this.state.hasSector}
                                        select
                                        label={i18n.t('Sector')}
                                        value={this.state.parcelObj.id_sektor}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px', marginLeft: '10px' }}
                                        onChange={this.handleChangeValue('id_sektor')}
                                    >

                                        <option value='' />
                                        {this.state.ogranakDropdown.map((sektor, index) => {
                                            return <option key={index} value={sektor.id}>{sektor.naziv}</option>
                                        })}
                                    </TextField>
                                </Grid>

                                <Grid item xs>
                                </Grid>

                                <Grid item xs={3}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => this.addParcel()}
                                        style={{ alignItems: 'right,', marginLeft: '10px', marginTop: '20px' }}
                                    >
                                        {this.props.edit ? i18n.t('Save') : i18n.t('Add')}
                                    </Button>
                                </Grid>

                            </Grid>
                        </form>}
                        {this.state.new && <form id="add-layer">
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Grid>
                                    <TextField
                                        required
                                        label={i18n.t('Title')}
                                        value={this.state.parcelObj.naziv}
                                        style={{ width: '540px', marginBottom: '10px', marginTop: '20px', marginLeft: '10px', marginRight: '10px' }}
                                        onChange={this.handleChangeValue('naziv')}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">g_</InputAdornment>,
                                        }}
                                    />
                                    <div className={styles.title_container}>
                                        <div className={styles.title_left}>
                                            <img src="./field_3.svg" height='26px' className={styles.title_icon} />
                                            <div className={styles.section_title}>{i18n.t('Parcels')}*</div>
                                            {(this.state.parcelObj.parcele === undefined || this.state.parcelObj.parcele.length === 0) &&
                                                <div className={styles.add_button} onClick={() => this.handleParcels()}>
                                                    <img src="./add.svg" className={styles.add_icon} height='18px' onClick={() => this.handleParcels()} />
                                                </div>
                                            }
                                        </div>
                                        {this.state.parcelObj.parcele && this.state.parcelObj.parcele.length > 0 && <img src="./edit.svg" height='26px' className={styles.edit_icon} onClick={() => this.handleParcels()} />}
                                    </div>
                                    {(this.state.parcelObj.parcele === undefined || this.state.parcelObj.parcele.length === 0) &&
                                        <div className={styles.content_container}>
                                            <div className={styles.content_empty}>{i18n.t('You have no selected parcels')}</div>
                                        </div>
                                    }
                                    {this.state.parcelObj.parcele && this.state.parcelObj.parcele.length > 0 &&
                                        <div className={styles.content_container}>
                                            <div className={styles.content_border} />
                                            <div className={styles.table_div}>
                                                <table className={styles.table}>
                                                    <tr className={styles.table_row}>
                                                        <th className={styles.table_header}>{i18n.t('Title')}</th>
                                                        <th className={styles.table_header}>{i18n.t('Land area')}</th>
                                                        <th className={styles.table_header}>{i18n.t('Local government')}</th>
                                                        <th className={styles.table_header_end}>{i18n.t('Delete')}</th>
                                                    </tr>
                                                    {this.state.parcelObj.parcele.map((item, i) => {
                                                        return <tr className={this.isOdd(i) === 0 ? styles.table_row_odd : styles.table_row}>
                                                            <td className={styles.table_row_text}>{item.broj}</td>
                                                            <td className={styles.table_row_text}>{(Number(item.povrsina)).toFixed(2)} ha</td>
                                                            <td className={styles.table_row_text}>{item.katastarska_opstina_naziv}</td>
                                                            <td className={styles.table_row_end}>
                                                                <img src="./delete.svg" height="24px" className={styles.delete_button_table} onClick={() => this.deleteParcels(i)} />
                                                            </td>
                                                        </tr>
                                                    })}
                                                </table>
                                            </div>
                                            {this.state.parcelObj.parcele.length > 0 &&
                                                <div style={{ margin: '6px 0px 0px 8px', fontWeight: '500', fontSize: '16px', display: 'flex', justifyContent: 'space-between' }}>
                                                    <div>{i18n.t('Total land area')}: {(this.state.parcelObj.parcele.reduce((prev, item) => prev + Number(item.povrsina), 0)).toFixed(2)} ha</div>
                                                    <div>{this.state.parcelObj.parcele[0].sektor_naziv ? `Ogranak: ${this.state.parcelObj.parcele[0].sektor_naziv}` : ""}</div>
                                                </div>
                                            }
                                        </div>
                                    }
                                </Grid>
                            </div>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => this.addParcelGrouped()}
                                style={{ alignItems: 'right,', marginLeft: '10px', marginTop: '20px' }}
                            >
                                {this.props.edit ? i18n.t('Save') : i18n.t('Save')}
                            </Button>
                        </form>}
                    </DialogContent>
                </Dialog>
                {this.state.parcelsOpen && <ManageParcels open={this.state.parcelsOpen} handleClose={this.handleParcels} handleParcelObj={this.handleParcelObj} parcelObj={this.state.parcelObj} />}

            </div>


        )
    }
};

function mapStateToProps(state) {
    return {
        parcelPosting: state.parcelReducer.parcelPosting,
        parcelPostingSuccess: state.parcelReducer.parcelPostingSuccess,
        parcelPostingFailed: state.parcelReducer.parcelPostingFailed,

        localGoverments: state.appReducer.localGoverments,
        localGovermentsFetching: state.appReducer.localGovermentsFetching,
        localGovermentsFetchingFailed: state.appReducer.localGovermentsFetchingFailed,

        kat_kulture: state.appReducer.kat_kulture,
        kat_kultureFetching: state.appReducer.kat_kultureFetching,
        kat_kultureFetchingFailed: state.appReducer.kat_kultureFetchingFailed,

        parcelObj: state.parcelReducer.parcelObj,
        ownershipDropDown: state.parcelReducer.ownershipDropDown,
        ownershipFetchingDropDown: state.parcelReducer.ownershipFetchingDropDown,
        ownershipFetchingFailedDropDown: state.parcelReducer.ownershipFetchingFailedDropDown,

    }
}

function mapDispatchToProps(dispatch) {
    return {
        setInitialState: () => dispatch(setInitialState()),
        getLocalGov: () => dispatch(getLocalGov()),
        getKatKultura: () => dispatch(getKatKultura()),
        getOwnershipDropDown: () => dispatch(getOwnershipDropDown()),
        addParcel: (parcelObj) => dispatch(addParcel(parcelObj)),
        addGroupedParcel: (parcelObj) => dispatch(addGroupedParcel(parcelObj)),

    }

}

export default withStyles(styles, { withTheme: true })(connect(
    mapStateToProps,
    mapDispatchToProps
)(AddParcel))